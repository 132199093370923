(function($) {
  $(function() {
    if ($body.hasClass('home')) {
      $('.c-banner__background-switch').on('mouseenter mouseleave', function() {
        $('.c-banner__background--active').removeClass('c-banner__background--active').siblings('.c-banner__background').addClass('c-banner__background--active');
      });
    }
    else {
      $('.c-banner__background-switch').on('mouseenter mouseleave', function() {
        $('.c-banner__background').toggleClass('c-banner__background--active');
      });
    }
  });
})(jQuery);
